import React, { FC, Fragment } from "react";
import { graphql } from "gatsby";
import { PageTemplateQuery } from "../../graphql-types";
import { spaceRem, colors } from "../global-styles";
import Img, { FluidObject } from "gatsby-image";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

export const query = graphql`
  query PageTemplate($id: ID!) {
    wp {
      page(id: $id) {
        title
        id
        blocks {
          ...Block
        }
      }
    }
  }
`;

const PageTemplate: FC<{ data: PageTemplateQuery }> = props => (
  <>
    <h1
      style={{
        marginTop: `${1 * spaceRem}rem`,
        marginBottom: `${1 * spaceRem}rem`,
        background: `linear-gradient(to right, ${colors.superiorWhite} 40%, rgba(255, 255, 255, 0))`,
        color: colors.orient,
        paddingTop: `${0.25 * spaceRem}rem`,
        paddingBottom: `${0.25 * spaceRem}rem`,
        fontSize: "1.3rem",
        lineHeight: `${1.5 * spaceRem}rem`,
      }}
      css={`
        padding-left: ${0.5 * spaceRem}rem;
        padding-right: ${0.5 * spaceRem}rem;

        @media (min-width: 600px) {
          padding-left: ${1.5 * spaceRem}rem;
          padding-right: ${1.5 * spaceRem}rem;
        }
      `}
      dangerouslySetInnerHTML={{ __html: props.data.wp.page?.title as string }}
    />

    <div
      css={`
        padding-left: ${0.5 * spaceRem}rem;
        padding-right: ${0.5 * spaceRem}rem;

        @media (min-width: 600px) {
          padding-left: ${1.5 * spaceRem}rem;
        }

        * + * {
          margin-top: ${0.5 * spaceRem}rem;
        }
      `}
    >
      {props.data.wp.page?.blocks?.map((block, i) => (
        <Fragment key={i}>
          {(() => {
            // core/paragraph
            if (
              block?.__typename === "Wp_CoreParagraphBlock" &&
              block.attributes?.__typename ===
                "Wp_CoreParagraphBlockAttributesV3"
            ) {
              return (
                <p
                  dangerouslySetInnerHTML={{ __html: block.attributes.content }}
                  css={`
                    a {
                      text-decoration: underline;
                      color: ${colors.orient};
                    }
                  `}
                />
              );
            }

            // core/heading
            if (block?.__typename === "Wp_CoreHeadingBlock") {
              return (
                (block.attributes?.level === 1 && (
                  <h1
                    style={{ color: colors.orient, fontSize: "1.1rem" }}
                    dangerouslySetInnerHTML={{
                      __html: block.attributes.content,
                    }}
                  />
                )) ||
                (block.attributes?.level === 2 && (
                  <h2
                    style={{ color: colors.orient, fontSize: "1.1rem" }}
                    dangerouslySetInnerHTML={{
                      __html: block.attributes.content,
                    }}
                  />
                )) ||
                (block.attributes?.level === 3 && (
                  <h3
                    style={{ color: colors.orient, fontSize: "1rem" }}
                    dangerouslySetInnerHTML={{
                      __html: block.attributes.content,
                    }}
                  />
                ))
              );
            }

            // core/image
            if (block?.__typename === "Wp_CoreImageBlock") {
              return block.attributes?.mediaItem?.localFile?.childImageSharp
                ?.fluid ? (
                // <Zoom
                //   wrapStyle={{
                //     width: "100%",
                //     display: "flex",
                //     justifyContent: "flex-end",
                //   }}
                // >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Img
                    fluid={
                      block.attributes?.mediaItem?.localFile?.childImageSharp
                        ?.fluid as FluidObject
                    }
                    style={{
                      width: "100%",
                      height: "100%",
                      maxHeight: "20rem",
                      maxWidth: "20rem",
                      padding: "1rem",
                    }}
                    imgStyle={{
                      width: "auto",
                      height: "auto",
                      maxHeight: "20rem",
                      maxWidth: "20rem",
                      left: undefined,
                      right: 0,
                    }}
                  />
                </div>
              ) : // </Zoom>
              null;
            }

            // acf/agenda
            if (block?.__typename === "Wp_AcfAgendaBlock") {
              return (
                <div
                  css={`
                    > * {
                      margin-bottom: ${2 * spaceRem}rem;
                    }
                  `}
                >
                  {block.acf?.agenda?.map((item, i) => (
                    <div key={i}>
                      <p>
                        <strong
                          style={{ color: colors.orient }}
                          dangerouslySetInnerHTML={{
                            __html: item.title as string,
                          }}
                        />
                      </p>

                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.description as string,
                        }}
                      />

                      <dl
                        css={`
                          > * {
                            display: flex;
                          }

                          * {
                            margin: 0;
                          }

                          dt {
                            font-weight: 600;
                            flex-shrink: 0;
                          }

                          dd {
                            margin-left: ${0.5 * spaceRem}rem;
                          }
                        `}
                      >
                        <div>
                          <dt>Datum</dt>
                          <dd
                            dangerouslySetInnerHTML={{
                              __html: item.date as string,
                            }}
                          />
                        </div>

                        <div>
                          <dt>Kosten</dt>
                          <dd
                            dangerouslySetInnerHTML={{
                              __html: item.costs as string,
                            }}
                          />
                        </div>
                      </dl>
                    </div>
                  ))}
                </div>
              );
            }

            // core/list
            if (block?.__typename === "Wp_CoreListBlock") {
              return (
                <ul
                  css={`
                    margin-bottom: ${1 * spaceRem}rem;
                    padding-left: ${2 * spaceRem}rem;

                    &,
                    ul {
                      list-style-type: disc;
                    }

                    ul {
                      padding-left: ${1.25 * spaceRem}rem;
                    }

                    ul {
                      margin-top: ${0.5 * spaceRem}rem;
                    }
                  `}
                  dangerouslySetInnerHTML={{
                    __html: block.attributes?.values as string,
                  }}
                />
              );
            }

            // core/list
            if (block?.__typename === "Wp_CoreColumnsBlock") {
              return (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    margin: `${-0.5 * spaceRem}rem`,
                  }}
                >
                  {block.innerBlocks.map((innerBlock, i) => (
                    <div
                      key={i}
                      style={{
                        margin: `${0.5 * spaceRem}rem`,
                        flexGrow: 1,
                        flexShrink: 0,
                        flexBasis: i === 0 ? "15rem" : "7rem",
                        ...(i > 0 && { alignSelf: "center" }),
                      }}
                    >
                      {innerBlock?.innerBlocks.map((innerBlock, i) => {
                        // core/paragraph
                        if (
                          innerBlock?.__typename === "Wp_CoreParagraphBlock" &&
                          innerBlock.attributes?.__typename ===
                            "Wp_CoreParagraphBlockAttributesV3"
                        ) {
                          return (
                            <p
                              key={i}
                              dangerouslySetInnerHTML={{
                                __html: innerBlock.attributes.content,
                              }}
                              css={`
                                a {
                                  text-decoration: underline;
                                  color: ${colors.orient};
                                }
                              `}
                            />
                          );
                        }

                        // core/image
                        if (innerBlock?.__typename === "Wp_CoreImageBlock") {
                          return innerBlock.attributes?.mediaItem?.localFile
                            ?.childImageSharp?.fluid ? (
                            <Img
                              key={i}
                              fluid={
                                innerBlock.attributes?.mediaItem?.localFile
                                  ?.childImageSharp?.fluid as FluidObject
                              }
                              style={{
                                width: "100%",
                                height: "100%",
                                maxHeight: "20rem",
                                maxWidth: "20rem",
                                padding: "1rem",
                              }}
                              imgStyle={{
                                margin: 0,
                                width: "100%",
                                height: "100%",
                                maxHeight: "20rem",
                                maxWidth: "20rem",
                                left: undefined,
                                right: 0,
                              }}
                            />
                          ) : null;
                        }

                        // acf/map
                        if (innerBlock?.__typename === "Wp_AcfMapBlock") {
                          return (
                            <iframe
                              key={i}
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2458.3227397234214!2d4.890892216112484!3d51.96454067971278!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c67e87066c2a89%3A0xd16ab694f3f3ac18!2sPraktijk%20voor%20wel%20zijn%20E.W.%20de%20Vos%20Oosterom!5e0!3m2!1snl!2snl!4v1588092354915!5m2!1snl!2snl"
                              frameBorder="0"
                              style={{
                                width: "100%",
                                height: "100%",
                                border: 0,
                              }}
                              allowFullScreen={false}
                              aria-hidden="false"
                              tabIndex={0}
                            ></iframe>
                          );
                        }

                        // core/heading
                        if (innerBlock?.__typename === "Wp_CoreHeadingBlock") {
                          return (
                            (innerBlock.attributes?.level === 1 && (
                              <h1
                                style={{
                                  color: colors.orient,
                                  fontSize: "1.1rem",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: innerBlock.attributes.content,
                                }}
                              />
                            )) ||
                            (innerBlock.attributes?.level === 2 && (
                              <h2
                                style={{
                                  color: colors.orient,
                                  fontSize: "1.1rem",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: innerBlock.attributes.content,
                                }}
                              />
                            )) ||
                            (innerBlock.attributes?.level === 3 && (
                              <h3
                                style={{
                                  color: colors.orient,
                                  fontSize: "1rem",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: innerBlock.attributes.content,
                                }}
                              />
                            ))
                          );
                        }
                      })}
                    </div>
                  ))}
                </div>
              );
            }

            // acf/map
            if (block?.__typename === "Wp_AcfMapBlock") {
              return (
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2458.3227397234214!2d4.890892216112484!3d51.96454067971278!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c67e87066c2a89%3A0xd16ab694f3f3ac18!2sPraktijk%20voor%20wel%20zijn%20E.W.%20de%20Vos%20Oosterom!5e0!3m2!1snl!2snl!4v1588092354915!5m2!1snl!2snl"
                  frameBorder="0"
                  style={{ width: "100%", border: 0 }}
                  allowFullScreen={false}
                  aria-hidden="false"
                  tabIndex={0}
                ></iframe>
              );
            }

            return <div>ontbrekende implementatie: {block?.__typename}</div>;
          })()}
        </Fragment>
      ))}
    </div>
  </>
);

export default PageTemplate;
